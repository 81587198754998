import request from '@/utils/request'
import qs from 'qs'
//查看优惠卷
export function CouponAll (id,state) {
  let data = {
   id,state
  }
  data = qs.stringify(data)
  return request({
    url: '/author/CouponAll',
    method: 'post',
    data
  })
}
// 我的红包
export function selectRedEnvelopesByAid (author_id,page,limit) {
  let data = {
    author_id,page,limit
  }
  data = qs.stringify(data)
  return request({
    url: '/author/selectRedEnvelopesByAid',
    method: 'post',
    data
  })
}

