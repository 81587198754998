<template>
	<div>
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="未使用" name="first">
				<div class="container_table">
					<el-table :header-cell-style="headeRowClass" :data="tableData" stripe style="width: 100%" :default-sort="{ prop: 'date', order: 'descending' }">
						<el-table-column  label="使用规则" >
							<template slot-scope="scope">
								 <div class="coupon">
									 <div class="couponmoney">
										  ${{scope.row.preferential}}
									 </div>
									 <div class="couponrule">
										<p>优惠券</p>
										<span>满{{scope.row.available}}可用</span>
									 </div>
									 <div class="topcircle"></div>
									 <div class="bottomcircle"></div>
								 </div>
							</template>
						</el-table-column>
						<el-table-column prop="expires" label="到期时间" width="180" align="center" sortable>
						</el-table-column>
						<el-table-column label="状态" align="center" >
							<template>
								<span>未使用</span>
							</template>
						</el-table-column>
					</el-table>
					<div class="ygdPage" style="display: flex; justify-content: center;width: 100%; margin-top:20px;">
						<el-pagination class="fy" small @size-change="handleSizeChange" :page-size="pagesize" :page-sizes="[10, 20, 30]"
						 layout="total, sizes, prev, pager, next, jumper" @current-change="current_change" :total="total" background>
						</el-pagination>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane label="已使用" name="second">
				<div class="container_table">
					<el-table :header-cell-style="headeRowClass" :data="tableData2" stripe style="width: 100%" :default-sort="{ prop: 'date', order: 'descending' }">
						<el-table-column  label="使用规则" >
							<template slot-scope="scope">
								 <div class="coupon">
									 <div class="couponmoney">
										 ${{scope.row.preferential}}
									 </div>
									 <div class="couponrule">
										<p>优惠券</p>
										<span>满{{scope.row.available}}可用</span>
									 </div>
									 <div class="topcircle"></div>
									 <div class="bottomcircle"></div>
								 </div>
							</template>
						</el-table-column>
						<el-table-column prop="expires" label="到期时间" width="180" align="center" sortable>
						</el-table-column>
						<el-table-column label="状态" align="center" >
							<template>
								<span>已使用</span>
							</template>
						</el-table-column>
					</el-table>
					<div class="ygdPage" style="display: flex; justify-content: center;width: 100%; margin-top:20px;">
						<el-pagination class="fy" small @size-change="handleSizeChange2" :page-size="pagesize2" :page-sizes="[10, 20, 30]"
						 layout="total, sizes, prev, pager, next, jumper" @current-change="current_change2" :total="total2" background>
						</el-pagination>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane label="已过期" name="third">
				<div class="container_table">
					<el-table :header-cell-style="headeRowClass" :data="tableData3" stripe style="width: 100%" :default-sort="{ prop: 'date', order: 'descending' }">
						<el-table-column  label="使用规则" >
							<template slot-scope="scope">
								 <div class="coupon">
									 <div class="couponmoney">
										 ${{scope.row.preferential}}
									 </div>
									 <div class="couponrule">
										<p>优惠券</p>
										<span>满{{scope.row.available}}可用</span>
									 </div>
									 <div class="topcircle"></div>
									 <div class="bottomcircle"></div>
								 </div>
							</template>
						</el-table-column>
						<el-table-column prop="expires" label="到期时间" width="180" align="center" sortable>
						</el-table-column>
						<el-table-column label="状态" align="center">
							<template>
								<span>已过期</span>
							</template>
						</el-table-column>
					</el-table>
					<div class="ygdPage" style="display: flex; justify-content: center;width: 100%; margin-top:20px;">
						<el-pagination class="fy" small @size-change="handleSizeChange3" :page-size="pagesize3" :page-sizes="[10, 20, 30]"
						 layout="total, sizes, prev, pager, next, jumper" @current-change="current_change3" :total="total3" background>
						</el-pagination>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>


<script>
	import {CouponAll} from "../../../API/ZZD/coupon.js"
	export default {
		data() {
			return {
				activeName: 'first',
				total: 0, //默认数据总数
				pagesize: 10, //每页的数据条数
				currentPage: 1, //默认开始页面
				total2: 0, //默认数据总数
				pagesize2: 10, //每页的数据条数
				currentPage2: 1, //默认开始页面
				total3: 0, //默认数据总数
				pagesize3: 10, //每页的数据条数
				currentPage3: 1, //默认开始页面
				tableData: [
					{
					"preferential": 20,
					"expires": "2021-01-03",
					"available": 100,
					"id": 3,
					"state": 2,
					"author_id": 1,
					"chart": ""
					}
				], tableData2: [
					{
					"preferential": 20,
					"expires": "2021-01-03",
					"available": 300,
					"id": 3,
					"state": 2,
					"author_id": 1,
					"chart": ""
					}
				],tableData3: [
					{
					"preferential": 20,
					"expires": "2021-01-03",
					"available": 200,
					"id": 3,
					"state": 2,
					"author_id": 1,
					"chart": ""
					}
				],// 提现记录列表
			}

		},
		mounted() {
			this.unusecoupon();
			this.usedcoupon();
			this.timeoutcoupon()
		},
		methods: {
			handleClick(tab, event) {
				console.log(tab, event);
			}, // 更改表头样式
			headeRowClass({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				if (rowIndex === 0) {
					return 'background-color: #F2F2F2;color: #666666;font-weight: 700;'
				}
			},
			// 分页
			current_change: function(currentPage) {
				this.currentPage = currentPage;
				this.unusecoupon();
			},
			handleSizeChange(val) {
				console.log(val);
				this.pagesize = val;
				this.unusecoupon();
			},
			// 分页
			current_change2: function(currentPage) {
				this.currentPage2 = currentPage;
				this.usedcoupon();
			},
			handleSizeChange2(val) {
				console.log(val);
				this.pagesize2 = val;
				this.usedcoupon();
			},
			// 分页
			current_change3: function(currentPage) {
				this.currentPage3 = currentPage;
				this.timeoutcoupon()
			},
			handleSizeChange3(val) {
				console.log(val);
				this.pagesize3 = val;
				this.timeoutcoupon()
			},
			//查询未使用优惠卷
			unusecoupon(){
				CouponAll(localStorage.getItem('ZZId'),0).then(res=>{
					console.log(res)
					this.tableData=res.data.data
					this.total=res.data.total
				})
			}
			,
			//查询已使用优惠卷
			usedcoupon(){
				CouponAll(localStorage.getItem('ZZId'),1).then(res=>{
					console.log(res)
					this.tableData2=res.data.data
					this.total2=res.data.total
				})
			}
			,
			//查询过期优惠卷
			timeoutcoupon(){
				CouponAll(localStorage.getItem('ZZId'),2).then(res=>{
					console.log(res)
					this.tableData3=res.data.data
					this.total3=res.data.total
				})
			}
		}
	}
</script>

<style>
.coupon{
	width:160px;
	height:90px;
	background-color: #FE5F60;
	color: #fff;
	display: flex;
	padding: 10px;
	text-align: center;
	position: relative;
}
.coupon .couponmoney{
	flex:1;
  
  line-height:70px;
  border-right: 1px dashed #fff;
	
}
.coupon .couponrule{
	flex:1;
	padding-top: 20px;
	
}
.topcircle,.bottomcircle{
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: #fff;
	position: absolute;
	left: 70px;
}
.topcircle{
	top:-10px;
}
.bottomcircle{
	bottom: -10px;
}
</style>
